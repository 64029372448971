import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Paris - Roubaix",
      edition: 2021,
      what: "Race data",
      updated: "2021-10-02 16:40:22",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: "21798",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
      },
      21799: {
        teamId: "21799",
        teamUciCode: "APT",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
      },
      21800: {
        teamId: "21800",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21805: {
        teamId: "21805",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      21807: {
        teamId: "21807",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      21808: {
        teamId: "21808",
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
      },
      21811: {
        teamId: "21811",
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
      },
      21813: {
        teamId: "21813",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21817: {
        teamId: "21817",
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
      },
      21819: {
        teamId: "21819",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21821: {
        teamId: "21821",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
      },
      21822: {
        teamId: "21822",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21824: {
        teamId: "21824",
        teamUciCode: "TQA",
        teamName: "Team Qhubeka NextHash",
        teamNationCode: "RSA",
      },
      21826: {
        teamId: "21826",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21829: {
        teamId: "21829",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21832: {
        teamId: "21832",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
      },
      21836: {
        teamId: "21836",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21838: {
        teamId: "21838",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21844: {
        teamId: "21844",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21849: {
        teamId: "21849",
        teamUciCode: "BBK",
        teamName: "B&B Hotels p/b KTM",
        teamNationCode: "FRA",
      },
      21852: {
        teamId: "21852",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      21855: {
        teamId: "21855",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
      },
      21859: {
        teamId: "21859",
        teamUciCode: "DKO",
        teamName: "Delko",
        teamNationCode: "FRA",
      },
      21864: {
        teamId: "21864",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
      },
      21867: {
        teamId: "21867",
        teamUciCode: "TEN",
        teamName: "Team TotalEnergies",
        teamNationCode: "FRA",
      },
    },
    riders: {
      120: {
        id: 120,
        startno: 73,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      8377: {
        id: 8377,
        startno: 54,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-10-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      27331: {
        id: 27331,
        startno: 112,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      20178: {
        id: 20178,
        startno: 163,
        firstName: "Cl\u00e9ment",
        lastName: "Carisey",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-23",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      19254: {
        id: 19254,
        startno: 212,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      14325: {
        id: 14325,
        startno: 126,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      18260: {
        id: 18260,
        startno: 12,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      95151: {
        id: 95151,
        startno: 65,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      66265: {
        id: 66265,
        startno: 122,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      31208: {
        id: 31208,
        startno: 173,
        firstName: "Cees",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-07-27",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      75603: {
        id: 75603,
        startno: 223,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      32892: {
        id: 32892,
        startno: 45,
        firstName: "Hugo",
        lastName: "Hofstetter",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-02-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      10971: {
        id: 10971,
        startno: 21,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      63055: {
        id: 63055,
        startno: 77,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      15686: {
        id: 15686,
        startno: 133,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      7723: {
        id: 7723,
        startno: 184,
        firstName: "Wesley",
        lastName: "Kreder",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      32609: {
        id: 32609,
        startno: 234,
        firstName: "J\u00e9r\u00e9my",
        lastName: "Lecroq",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-04-07",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      7720: {
        id: 7720,
        startno: 34,
        firstName: "Dylan",
        lastName: "Groenewegen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-06-21",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16724: {
        id: 16724,
        startno: 93,
        firstName: "Stefan",
        lastName: "K\u00fcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      37800: {
        id: 37800,
        startno: 144,
        firstName: "Florian",
        lastName: "Ma\u00eetre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-09-03",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      9894: {
        id: 9894,
        startno: 194,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      71209: {
        id: 71209,
        startno: 245,
        firstName: "Tom",
        lastName: "Paquot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-22",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      16615: {
        id: 16615,
        startno: 46,
        firstName: "Mads",
        lastName: "W\u00fcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      46157: {
        id: 46157,
        startno: 102,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      50030: {
        id: 50030,
        startno: 155,
        firstName: "Barnabas",
        lastName: "Peak",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-11-29",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      65437: {
        id: 65437,
        startno: 204,
        firstName: "Juri",
        lastName: "Hollmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-08-30",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      1715: {
        id: 1715,
        startno: 154,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      63035: {
        id: 63035,
        startno: 4,
        firstName: "S\u00e9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      7964: {
        id: 7964,
        startno: 57,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      38940: {
        id: 38940,
        startno: 115,
        firstName: "Eddy",
        lastName: "Fin\u00e9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-11-20",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      32893: {
        id: 32893,
        startno: 167,
        firstName: "Julien",
        lastName: "Trarieux",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-08-19",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 216,
        firstName: "Cl\u00e9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 101,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      777: {
        id: 777,
        startno: 15,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      2338: {
        id: 2338,
        startno: 71,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 125,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      6414: {
        id: 6414,
        startno: 177,
        firstName: "Jasha",
        lastName: "S\u00fctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      14717: {
        id: 14717,
        startno: 226,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      20104: {
        id: 20104,
        startno: 213,
        firstName: "Benjamin",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-04",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      12481: {
        id: 12481,
        startno: 25,
        firstName: "Florian",
        lastName: "S\u00e9n\u00e9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      22682: {
        id: 22682,
        startno: 85,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 136,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      1267: {
        id: 1267,
        startno: 187,
        firstName: "Pieter",
        lastName: "Vanspeybrouck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-02-10",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      5297: {
        id: 5297,
        startno: 237,
        firstName: "Jonas",
        lastName: "Van Genechten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-09-16",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      16583: {
        id: 16583,
        startno: 37,
        firstName: "Nathan",
        lastName: "Van Hooydonck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-12",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      12493: {
        id: 12493,
        startno: 95,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      29: {
        id: 29,
        startno: 142,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      9210: {
        id: 9210,
        startno: 197,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-13",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      31612: {
        id: 31612,
        startno: 83,
        firstName: "Lawrence",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-08-28",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      18258: {
        id: 18258,
        startno: 31,
        firstName: "Wout",
        lastName: "Van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37430: {
        id: 37430,
        startno: 53,
        firstName: "Senne",
        lastName: "Leysen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-03-18",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      14737: {
        id: 14737,
        startno: 111,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      38289: {
        id: 38289,
        startno: 162,
        firstName: "Pierre",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-09-25",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      49662: {
        id: 49662,
        startno: 211,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      16797: {
        id: 16797,
        startno: 233,
        firstName: "Quentin",
        lastName: "Jauregui",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-22",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      69912: {
        id: 69912,
        startno: 2,
        firstName: "Florian",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-03-12",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16793: {
        id: 16793,
        startno: 64,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      406: {
        id: 406,
        startno: 121,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      45368: {
        id: 45368,
        startno: 174,
        firstName: "Nils",
        lastName: "Eekhoff",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-01-23",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      82115: {
        id: 82115,
        startno: 222,
        firstName: "Gleb",
        lastName: "Brussenskiy",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-04-18",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      14902: {
        id: 14902,
        startno: 42,
        firstName: "Rudy",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-18",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      27121: {
        id: 27121,
        startno: 22,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      376: {
        id: 376,
        startno: 76,
        firstName: "Marcel",
        lastName: "Sieberg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1982-04-30",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 132,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      18249: {
        id: 18249,
        startno: 183,
        firstName: "Tom",
        lastName: "Devriendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-29",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      1966: {
        id: 1966,
        startno: 232,
        firstName: "Jens",
        lastName: "Debusschere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-08-28",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      37308: {
        id: 37308,
        startno: 33,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      327: {
        id: 327,
        startno: 86,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      31: {
        id: 31,
        startno: 143,
        firstName: "Edvald",
        lastName: "Boasson-Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      568: {
        id: 568,
        startno: 193,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      20822: {
        id: 20822,
        startno: 244,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 44,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      312: {
        id: 312,
        startno: 104,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      2212: {
        id: 2212,
        startno: 152,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      333: {
        id: 333,
        startno: 203,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      704: {
        id: 704,
        startno: 153,
        firstName: "Sam",
        lastName: "Bewley",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1987-07-22",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      16144: {
        id: 16144,
        startno: 3,
        firstName: "Frederik",
        lastName: "Frison",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-07-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      20832: {
        id: 20832,
        startno: 56,
        firstName: "Jonas",
        lastName: "Rickaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-07",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      1982: {
        id: 1982,
        startno: 114,
        firstName: "Jempy",
        lastName: "Drucker",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1986-09-03",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      37264: {
        id: 37264,
        startno: 166,
        firstName: "Dusan",
        lastName: "Rajovic",
        nationCode: "SBA",
        nationName: "Serbia",
        birthDate: "1997-11-19",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      13898: {
        id: 13898,
        startno: 215,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-11-29",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      7910: {
        id: 7910,
        startno: 106,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      45364: {
        id: 45364,
        startno: 14,
        firstName: "Jordi",
        lastName: "Meeus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-01",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      10382: {
        id: 10382,
        startno: 67,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      18575: {
        id: 18575,
        startno: 124,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      37219: {
        id: 37219,
        startno: 176,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      5836: {
        id: 5836,
        startno: 225,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      37397: {
        id: 37397,
        startno: 92,
        firstName: "Cl\u00e9ment",
        lastName: "Davy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-07-17",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      1970: {
        id: 1970,
        startno: 24,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      37427: {
        id: 37427,
        startno: 82,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 135,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      3095: {
        id: 3095,
        startno: 186,
        firstName: "Kevin",
        lastName: "Van Melsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-04-01",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      46186: {
        id: 46186,
        startno: 236,
        firstName: "Luca",
        lastName: "Mozzato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-15",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      11313: {
        id: 11313,
        startno: 36,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      10871: {
        id: 10871,
        startno: 94,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      2326: {
        id: 2326,
        startno: 146,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      2982: {
        id: 2982,
        startno: 196,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      18574: {
        id: 18574,
        startno: 246,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-05-22",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      784: {
        id: 784,
        startno: 11,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      3303: {
        id: 3303,
        startno: 52,
        firstName: "Silvan",
        lastName: "Dillier",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1990-08-03",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      46161: {
        id: 46161,
        startno: 105,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      7755: {
        id: 7755,
        startno: 161,
        firstName: "Evaldas",
        lastName: "Siskevicius",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-12-30",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      6409: {
        id: 6409,
        startno: 207,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      8076: {
        id: 8076,
        startno: 27,
        firstName: "Bert",
        lastName: "Van Lerberghe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-09-29",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      1717: {
        id: 1717,
        startno: 6,
        firstName: "Tosh",
        lastName: "Van der Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-11-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      8740: {
        id: 8740,
        startno: 62,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37294: {
        id: 37294,
        startno: 117,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      5306: {
        id: 5306,
        startno: 172,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 221,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      16672: {
        id: 16672,
        startno: 51,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16643: {
        id: 16643,
        startno: 17,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      80489: {
        id: 80489,
        startno: 74,
        firstName: "Jonathan",
        lastName: "Milan",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 131,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16581: {
        id: 16581,
        startno: 182,
        firstName: "Aim\u00e9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      939: {
        id: 939,
        startno: 231,
        firstName: "Bert",
        lastName: "De Backer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1984-04-02",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      27097: {
        id: 27097,
        startno: 32,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      7580: {
        id: 7580,
        startno: 88,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      16637: {
        id: 16637,
        startno: 141,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      2146: {
        id: 2146,
        startno: 192,
        firstName: "Dimitri",
        lastName: "Claeys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-06-18",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      865: {
        id: 865,
        startno: 243,
        firstName: "Timothy",
        lastName: "Dupont",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-11-01",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      16787: {
        id: 16787,
        startno: 43,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      65036: {
        id: 65036,
        startno: 97,
        firstName: "Jake",
        lastName: "Stewart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-10-02",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 151,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      37373: {
        id: 37373,
        startno: 202,
        firstName: "Gabriel",
        lastName: "Cullaigh",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-04-08",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      68206: {
        id: 68206,
        startno: 206,
        firstName: "Matteo",
        lastName: "Jorgenson",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1999-07-01",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      174: {
        id: 174,
        startno: 1,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      45363: {
        id: 45363,
        startno: 55,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-03-02",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16690: {
        id: 16690,
        startno: 113,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-17",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      11191: {
        id: 11191,
        startno: 165,
        firstName: "August",
        lastName: "Jensen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1991-08-29",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      16154: {
        id: 16154,
        startno: 214,
        firstName: "Daniel",
        lastName: "McLay",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-01-03",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      41368: {
        id: 41368,
        startno: 164,
        firstName: "Alexandre",
        lastName: "Delettre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-10-25",
        teamId: 21859,
        teamName: "Delko",
        stillInTheRace: "Y",
      },
      361: {
        id: 361,
        startno: 13,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 66,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      4953: {
        id: 4953,
        startno: 123,
        firstName: "Sven Erik",
        lastName: "Bystr\u00f8m",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1992-01-21",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      37756: {
        id: 37756,
        startno: 175,
        firstName: "Max",
        lastName: "Kanter",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-22",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      27257: {
        id: 27257,
        startno: 224,
        firstName: "Yevgeniy",
        lastName: "Gidich",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-05-19",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      8813: {
        id: 8813,
        startno: 63,
        firstName: "Emils",
        lastName: "Liepins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1992-10-29",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      26504: {
        id: 26504,
        startno: 23,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 81,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      926: {
        id: 926,
        startno: 134,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-04-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      1987: {
        id: 1987,
        startno: 185,
        firstName: "Baptiste",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-09-28",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      170: {
        id: 170,
        startno: 235,
        firstName: "Cyril",
        lastName: "Lemoine",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1983-03-03",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
        stillInTheRace: "Y",
      },
      22133: {
        id: 22133,
        startno: 35,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      2337: {
        id: 2337,
        startno: 91,
        firstName: "Arnaud",
        lastName: "D\u00e9mare",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-08-26",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3045: {
        id: 3045,
        startno: 145,
        firstName: "Adrien",
        lastName: "Petit",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-09-26",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      6559: {
        id: 6559,
        startno: 195,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1989-02-03",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      27086: {
        id: 27086,
        startno: 247,
        firstName: "Tom",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1996-03-04",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      7579: {
        id: 7579,
        startno: 47,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-04-19",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      941: {
        id: 941,
        startno: 103,
        firstName: "Mitch",
        lastName: "Docker",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-10-02",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      44537: {
        id: 44537,
        startno: 156,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      38481: {
        id: 38481,
        startno: 205,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 75,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      44622: {
        id: 44622,
        startno: 5,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-07-09",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      5059: {
        id: 5059,
        startno: 61,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37286: {
        id: 37286,
        startno: 116,
        firstName: "Andr\u00e9",
        lastName: "Carvalho",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1997-10-31",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16610: {
        id: 16610,
        startno: 171,
        firstName: "S\u00f8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      37495: {
        id: 37495,
        startno: 217,
        firstName: "Bram",
        lastName: "Welten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-29",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      38164: {
        id: 38164,
        startno: 107,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      2172: {
        id: 2172,
        startno: 16,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1988-12-23",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      6246: {
        id: 6246,
        startno: 72,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      3933: {
        id: 3933,
        startno: 127,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      10802: {
        id: 10802,
        startno: 181,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      16594: {
        id: 16594,
        startno: 227,
        firstName: "Ben",
        lastName: "Perry",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1994-03-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      37758: {
        id: 37758,
        startno: 242,
        firstName: "Jonas",
        lastName: "Castrique",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-01-13",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 26,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      27304: {
        id: 27304,
        startno: 87,
        firstName: "Damien",
        lastName: "Touz\u00e9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-07",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      2313: {
        id: 2313,
        startno: 137,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 191,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      71213: {
        id: 71213,
        startno: 241,
        firstName: "Laurenz",
        lastName: "Rex",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-12-15",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      1581: {
        id: 1581,
        startno: 41,
        firstName: "Sep",
        lastName: "Vanmarcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-07-28",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      4589: {
        id: 4589,
        startno: 96,
        firstName: "Ramon",
        lastName: "Sinkeldam",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-02-09",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      16789: {
        id: 16789,
        startno: 147,
        firstName: "Dries",
        lastName: "van Gestel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-30",
        teamId: 21867,
        teamName: "Team TotalEnergies",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 201,
        firstName: "Ivan",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 7,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Paris Roubaix"
  const raceID = 8

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
